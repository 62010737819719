import { createReducer } from '@reduxjs/toolkit';

// Constants

const Types = {
  SET_TAG: 'user/SET_TAG',
  SET_TAG_NAME: 'user/SET_TAG_NAME',
  REMOVE_TAG: 'user/REMOVE_TAG',
};

// Reducers

const initialState = { tag: '', isTagLocked: false, isTagOnlyDelivery: false };

const userReducer = createReducer(initialState, {
  [Types.SET_TAG]: (state, action) => action.payload,
  [Types.SET_TAG_NAME]: (state, action) => ({
    ...state,
    tag: action.payload.tag,
  }),
  [Types.REMOVE_TAG]: () => initialState,
});

export const reducers = userReducer;

// Action creators

export const Creators = {
  setTag: (payload) => {
    return {
      type: Types.SET_TAG,
      payload: {
        tag: payload.tag || '',
        isTagLocked: payload.isTagLocked || false,
        isTagOnlyDelivery: payload.isTagOnlyDelivery || false,
        allowAnonymous: payload.allowAnonymous || false,
        accountId: payload.accountId || '',
        checkoutId: payload.checkoutId || '',
        isPostPaid: payload.isPostPaid || false,
        requiredFields: payload.requiredFields || [],
        eventPointId: payload.eventPointId || null,
        isEventPointLocked: payload.isEventPointLocked || false,
        tenantId: payload.tenantId || null,
      },
    };
  },
  setTagName: (payload) => {
    return {
      type: Types.SET_TAG_NAME,
      payload: {
        tag: payload.tag || '',
      },
    };
  },
  removeTag: () => {
    return {
      type: Types.REMOVE_TAG,
    };
  },
};
