class EcommerceService {
  static base = '';

  static create = this.base;

  static menus = (id) => `${this.base}/menus`;

  static product = (eventPointId, sku) =>
    `${this.base}/menus?event_point_id=${eventPointId}&sku=${sku}`;

  static getEventPoints = () => `${this.base}/event_points`;

  static getEventPoint = (eventPointId, tenantId = null) => {
    const point = `${this.base}/event_points/${eventPointId}`;
    if (tenantId) {
      return point.concat(`?tenant_id=${tenantId}`);
    }

    return point;
  };

  static getAddresses = () => '/profile/address';

  static getAddressesWithDistances = (eventPointId) =>
    `/profile/address-distance/${eventPointId}`;

  static checkAddressValidity = (eventPointId, address) =>
    `/event_points/${eventPointId}?address=${address.street}&number=${address.number}`.concat(
      `&zipcode=${address.zipcode}&city=${address.city}&state=${address.state}`,
    );

  static checkAddressCoordinatesValidity = (
    eventPointId,
    latitude,
    longitude,
  ) =>
    `/event_points/${eventPointId}?latitude=${latitude}&longitude=${longitude}`;

  static cardKey = (pointId) =>
    `${this.base}/cards/key?event_point_id=${pointId}`;

  static removeCard = (cardId) => `${this.base}/cards/${cardId}`;

  static payments = (uuid) => `${this.base}/payments/${uuid}`;

  static finishOrder = () => `${this.base}/transactions`;

  static transaction = (id) => `${this.base}/transactions/${id}`;

  static history = (offset, limit = 20) =>
    `${this.base}/history?offset=${offset}&limit=${limit}`;

  static historyItem = (orderId) => `${this.base}/history/${orderId}`;

  static cancelOrder = () => `${this.base}/orders/cancel`;

  static finishPostPaidOrder = () => `${this.base}/eyepass/checkout`;

  static fetchCheckoutByUuid = (uuid) =>
    `${this.base}/eyepass/checkout/fetch/${uuid}`;
}

export default EcommerceService;
