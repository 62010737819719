import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import gateway from '../../utils/gateway';
import ContextService from '../../services/context';
import EcommerceService from '../../services/ecommerce';
import { shippingTypes } from '../../utils/constants';
import showToast from '../../utils/showToast';
import mapStore from '../../utils/mapStore';
import ContextScreen from './ContextScreen';
import { Creators as tagActionCreators } from '../../store/modules/tag';
import * as productOrderActions from '../../store/modules/productOrder';
import ContextManager from '../../utils/ContextManager';
import SessionStorageManager from '../../utils/SessionStorageManager';

const mapStateToProps = ({ auth, tenantEyepass: { domain, tenantId } }) => ({
  isLogged: auth.isLogged,
  domain,
  tenantId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTag: tagActionCreators.setTag,
      removeTag: tagActionCreators.removeTag,
      setStore: productOrderActions.setStore,
      setShippingType: productOrderActions.setShippingType,
      clearStore: productOrderActions.clearStore,
      clearOrder: productOrderActions.clearOrder,
    },
    dispatch,
  );

const ContextContainer = ({
  history,
  match,
  setTag,
  removeTag,
  setStore,
  setShippingType,
  clearStore,
  clearOrder,
  isLogged,
  domain,
  tenantId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const { uuid } = match.params;

  const handleEventPoint = async (eventPointId, isEventPointLocked) => {
    try {
      const response = await gateway
        .get(
          EcommerceService.getEventPoint(
            eventPointId,
            isLogged ? null : tenantId,
          ),
        )
        .then(({ data }) => data);

      const { event_point: eventPoint } = response;

      const store = (eventPoint && mapStore(eventPoint)) || null;

      setStore({
        store,
        isEventPointLocked: isEventPointLocked || false,
      });

      setShippingType({ shippingType: shippingTypes.TAG });

      history.replace(`/${domain}/carrinho`);
    } catch (e) {
      showToast(e.message, 'error');
      setHasError(true);
      setIsLoading(false);
    }
  };

  const handleTagSave = (tagInfo) => {
    setTag(tagInfo);
    ContextManager.setTag(tagInfo);
  };

  const handleFetchContext = async () => {
    try {
      let context = null;

      try {
        const response = await gateway
          .get(EcommerceService.fetchCheckoutByUuid(uuid))
          .then(({ data }) => data);
        context = response;
      } catch (err) {
        console.error(err);
      }

      if (!context) {
        const response = await gateway
          .get(ContextService.link(uuid))
          .then(({ data }) => data);
        context = response.context;
      }

      if (String(context.data.tenantId) === String(tenantId)) {
        const {
          eventPointId,
          isEventPointLocked,
          tag,
          isTagLocked,
          isAnon,
          accountId,
          checkoutId,
          isPostPaid,
          requiredFields,
        } = context.data;

        const { type } = context;

        handleTagSave({
          tag,
          isTagLocked,
          isTagOnlyDelivery: type === 'TAG',
          allowAnonymous: isAnon,
          accountId,
          checkoutId,
          isPostPaid,
          requiredFields,
          eventPointId,
          isEventPointLocked,
          tenantId,
        });

        if (eventPointId) {
          handleEventPoint(eventPointId, isEventPointLocked);
        } else {
          history.replace(`/${domain}/carrinho`);
        }
      } else {
        showToast(t('context:wrongTenantId'), 'error');
      }
      setIsLoading(false);
    } catch (e) {
      showToast(e.message, 'error');
      setHasError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    removeTag();
    clearStore();
    clearOrder();
    ContextManager.removeTag();
    SessionStorageManager.clear();
    handleFetchContext();
  }, []);

  return <ContextScreen isLoading={isLoading} hasError={hasError} />;
};

ContextContainer.defaultProps = {
  domain: null,
};

ContextContainer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setTag: PropTypes.func.isRequired,
  setStore: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  clearStore: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired,
  domain: PropTypes.string,
  tenantId: PropTypes.number.isRequired,
  setShippingType: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextContainer);
